<template>
  <a-modal v-model="modalVisible" title="参数配置" :width="560" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :label-col="{span:7}" :wrapper-col="{span:12}">
      <a-form-model-item v-for="(item, index) in formList" :key="index" :label="item.devparamcfgname">
        <a-input v-model.trim="paramsMap[item.devparamcfgnum]" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getDeviceparamListByCondition, getDeviceparamValueList, setDeviceparamValueList } from 'A/ai'
export default {
  props: {
    visible: {
      default: false
    },
    devicemoduletype: {
      default: '1'
    },
    devicenum: {
      default: ''
    },
    modelnum: {
      default: ''
    },
  },
  data() {
    return {
      modalVisible: false,
      formList: [],
      paramsMap: {},
      paramsBak: {},
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      this.resetDatas();
      this.getDeviceparamListByCondition();
    },
    resetDatas() {
      this.formList = [];
      this.paramsMap = {};
      this.paramsBak = {};
    },
    getDeviceparamListByCondition() {
      this.showLoading();
      let params = {
        devicemoduletype: this.devicemoduletype
      }
      getDeviceparamListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.formList = res.item;
          this.getDeviceparamValueList();
        }
      })
    },
    getDeviceparamValueList() {
      let data = {
        modeltype: this.devicemoduletype,
        modelnum: this.modelnum,
        params: 'all',
      }
      let params = {
        devicenum: this.devicenum,
        data: [data]
      }
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0' && res.item.length) {
          this.paramsMap = res.item[0].params;
          this.paramsBak = {
            ...res.item[0].params
          };
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    confirm() {
      // success fail
      let flag = false;
      let data = {};
      for(let key in this.paramsMap) {
        if(this.paramsMap[key] != this.paramsBak[key]) {
          data[key] = this.paramsMap[key];
          flag = true;
        }
      }
      if(flag) {
        this.showLoading();
        let params = {
          devicenum: this.devicenum,
          data: [{
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: data
          }]
        }
        setDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.$emit('get-operation-result', 'success', '操作成功');
            this.modalVisible = false;
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        }).catch(err => {
          this.hideLoading();
        })
      }else {
        this.modalVisible = false;
      }
    }
  }
}
</script>