<template>
  <page-container title="设备列表" :breadcrumb="breadcrumb">
    <div class="box-container">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <a-tabs size="small">
          <a-tab-pane key="tab1" tab="设备列表">
            <device-list></device-list>
          </a-tab-pane>
<!--          <a-tab-pane key="tab2" tab="基础音视频模块"></a-tab-pane>-->
<!--          <a-tab-pane key="tab3" tab="物联网模块"></a-tab-pane>-->
<!--          <a-tab-pane key="tab4" tab="视频AI模块"></a-tab-pane>-->
<!--          <a-tab-pane key="tab5" tab="通话服务模块"></a-tab-pane>-->
<!--          <a-tab-pane key="tab6" tab="语音AI模块"></a-tab-pane>-->
<!--          <a-tab-pane key="tab7" tab="显示屏列表"></a-tab-pane>-->
          <a-tab-pane key="tab8" tab="服务器列表">
            <ai-server-list></ai-server-list>
          </a-tab-pane>
          <a-tab-pane key="tab9" tab="设备版本号">
            <version></version>
          </a-tab-pane>
        </a-tabs>
      </div>
<!--      <device-list v-if="activeKey == 'tab1'"></device-list>-->
<!--      <ai-camera-list v-if="activeKey == 'tab2'"></ai-camera-list>-->
<!--      <ai-network-list v-if="activeKey == 'tab3'"></ai-network-list>-->
<!--      <ai-video-list v-if="activeKey == 'tab4'"></ai-video-list>-->
<!--      <ai-linphone-list v-if="activeKey == 'tab5'"></ai-linphone-list>-->
<!--      <ai-voice-list v-if="activeKey == 'tab6'"></ai-voice-list>-->
<!--      <ai-monitor-list v-if="activeKey == 'tab7'"></ai-monitor-list>-->
<!--      <ai-server-list v-if="activeKey == 'tab8'"></ai-server-list>-->
<!--      <version v-if="activeKey == 'tab9'"></version>-->
    </div>
  </page-container>
</template>
<script>
import DeviceList from './deviceList/Index'
import aiCameraList from './aiCameraList/Index'
import aiNetworkList from './aiNetworkList/Index'
import aiVideoList from './aiVideoList/Index'
import aiLinphoneList from './aiLinphoneList/Index'
import aiVoiceList from './aiVoiceList/Index'
import aiMonitorList from './aiMonitorList/Index'
import aiServerList from './aiServerList/Index'
import version from './version/Index'
import store from '@/store'
import { getItemFromArrayByKey } from 'U'
export default {
  name: 'enquipmentList',
  components: {
    DeviceList,
    aiCameraList,
    aiNetworkList,
    aiVideoList,
    aiLinphoneList,
    aiVoiceList,
    aiMonitorList,
    aiServerList,
    version
  },
  data() {
    return {
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '监控硬终端',
          path: ''
        },
        {
          name: '设备列表',
          path: ''
        },
      ],
      activeKey: 'tab1'
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name)
  //   if(!toTab) {
  //     store.commit('delKeepAlive', to.name)
  //   }
  //   next()
  // },
  // beforeRouteLeave(to, from, next) {
  //   console.log('=beforeRouteLeave=')
  //   next();
  // },
  created() {
    // console.log('=created=')
    // this.$ws.connect((ws) => {
    //   ws.send('{"devicenum":"001"}')
    // });
  },
  beforeDestroy() {
    // console.log('=beforeDestroy=')
    // this.$ws.disconnect();
  },
  methods: {
    // clickLeftMenu(event) {
    //   this.tabKey = event.key
    // },
  }
}
</script>

<style lang="scss" scoped>
.tab-container {
  padding: 0 24px;
  background-color: white;
}
.main-content {
  padding: 24px;
}
</style>