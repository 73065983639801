<!--服务器列表-->
<template>
  <div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="服务器名称" prop="systemname">
              <a-input v-model="queryParams.systemname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="服务器编号" prop="systemnum">
              <a-input v-model="queryParams.systemnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 0px -30px 0px -30px">
        <div class="table-header">
          <h3 class="table-title">服务器列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="systemid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.systemid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.systemid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.systemid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </div>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getItemFromArrayByKey} from 'U'
import {getServerSystemListByCondition} from 'A/ai.js'
import addOrEditModal from './addOrEditModal'
import {deleteServerSystem} from "A/ai";
export default {
  mixins: [pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      showAdvanced: false,
      monitortypes: [],
      queryParams: {
        systemid:'',
        systemname: '',
        systemnum: '',
        systemdecs:'',
      },
      tableColumns: [
        {
          title: '服务器名称',
          dataIndex: 'systemname',
          key: 'systemname',
          ellipsis: true,
        },
        {
          title: '服务器编号',
          dataIndex: 'systemnum',
          key: 'systemnum',
          ellipsis: true,
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
        {
          title: '描述',
          dataIndex: 'systemdecs',
          key: 'systemdecs',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      username:'',
    }
  },
  created() {
    this.getTableData(true);
  },
  methods: {
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getServerSystemListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.systemid);
      }).catch(()=>{
      });
    },
    delete(systemid) {
      this.showLoading();
      if(systemid) {
        let params = {
          systemid
        };
        deleteServerSystem(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let systemid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'systemid', systemid);
      if(type == 'delete') {
        this.deleteConfirm(systemid, record)
      } else {
        this.showModal(type, systemid, record)
      }
    },
  }
}
</script>