<template>
  <div>
    <div class="box-container" style="margin-top: 20px;">
      <div class="box-container-inner" style="margin: -20px -30px 0px -30px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="监控端名称" prop="devicename" style="width: 25%">
              <a-input v-model.trim="queryParams.devicename" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="监控端ID" prop="devicenum" style="width: 25%">
              <a-input v-model.trim="queryParams.devicenum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float:right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="监控客户端类型" prop="terminaltype"  style="width: 25%">
              <a-select v-model="queryParams.terminaltype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目名称" prop="monitorpointname" style="width: 25%">
              <a-input v-model.trim="queryParams.monitorpointname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目地址" prop="address" style="width: 25%">
              <a-input v-model.trim="queryParams.address" placeholder="请输入"></a-input>
            </a-form-model-item>
<!--            <a-form-model-item label="设备组名称" prop="deviceGroupname">-->
<!--              <a-input v-model.trim="queryParams.deviceGroupname" placeholder="请输入"></a-input>-->
<!--            </a-form-model-item>-->
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 0px -30px 0px -30px">
        <div class="table-header">
          <h3 class="table-title">设备列表</h3>
          <div class="table-btns">
            <a-button v-if="userInfo.usertype=='0'" type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="deviceinfoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="terminaltype" slot-scope="text">
            <span class="text">{{terminaltypesMap[text]||''}}</span>
          </span>
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.deviceinfoid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.deviceinfoid">修改</a-menu-item>
                <a-menu-item v-if="deleteenable==1" :key="'delete-'+record.deviceinfoid">删除</a-menu-item>
                <a-menu-item :key="'recover-'+record.deviceinfoid" >重启</a-menu-item>
                <a-menu-item v-if="deviceparamable==1" :key="'params-'+record.deviceinfoid" >基础参数配置</a-menu-item>
                <a-menu-item v-if="deviceparamable==1" :key="'update-'+record.deviceinfoid" >版本升级</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :terminaltypes="terminaltypes" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    <update-version :visible.sync="updateModalVisible" @get-operation-result="getTableData" :devicenum="devicenum"></update-version>
  </div>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getCache, getItemFromArrayByKey} from 'U'
import {
  getTerminaltypeListByCondition,
  getDeviceinfoListByCondition,
  deleteDeviceinfo,
  setDeviceparamValueList, addRecoverLog
} from 'A/ai'
import addOrEditModal from './addOrEditModal'
import UpdateVersion from '../aiCameraList/UpdateVersion'
import moment from "moment";
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
export default {
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
    UpdateVersion,
  },
  data() {
    return {
      showAdvanced: false,
      terminaltypes: [],
      userdepidCascaderSelected: [],
      queryParams: {
        devicename: '',
        devicenum: '',
        terminaltype: '',
        monitorpointnum: '',
        address: '',
        deviceGroupname:'',
        devicegroupid:'',
      },
      tableColumns: [
        {
          title: '监控端名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '监控端ID',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '监控端型号',
          dataIndex: 'terminaltype',
          key: 'terminaltype',
          ellipsis: true,
          scopedSlots: { customRender: 'terminaltype' }
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          width: 70,
          scopedSlots: { customRender: 'isonline' }
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'devicedecs',
          key: 'devicedecs',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      updateModalVisible: false,
      devicenum: '',
      devicegroupid:'',
      username:'',
      deleteenable:'',
      deviceparamable:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    terminaltypesMap() {
      let result = {};
      this.terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    },
  },
  created() {
    this.getTerminaltypes();
    this.initDeptOptionsAll();
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.username = logininfo.usernames;
      this.deleteenable = logininfo.deleteenable;
      this.deviceparamable = logininfo.deviceparamable;
    }
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  methods: {
    getTerminaltypes() {
      getTerminaltypeListByCondition({}).then(res => {
        if(res && res.returncode == '0') {
          this.terminaltypes = res.item.map(item => {
            return {
              value: item.terminaltypeid,
              text: item.terminaltypename,
            }
          });
        }
        this.getTableData();
      }).catch(err => {
        this.getTableData();
      })
    },
    resetQueryParams() {
      this.queryParams.deviceGroupname='';
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.userdepidCascaderSelected=[];
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getDeviceinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.deviceinfoid);
      }).catch(()=>{
      });
    },
    delete(deviceinfoid) {
      this.showLoading();
      if(deviceinfoid) {
        let params = {
          deviceinfoid
        };
        deleteDeviceinfo(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let deviceinfoid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'deviceinfoid', deviceinfoid);
      if(type == 'delete') {
        this.deleteConfirm(deviceinfoid, record)
      }else if(type == 'params') {
        this.$router.push({
          name: 'paramsConfig',
          params: {
            prePages: [
              {
                name: '网络管理',
                path: ''
              },
              {
                name: '监控硬终端',
                path: ''
              },
              {
                name: '设备列表',
                path: 'enquipmentList'
              },
            ],
            devicenum: record.devicenum,
            monitorpointid:record.monitorpointid,
            deviceinfoid:record.deviceinfoid,
            paramFlag:{
              mqttFlag:0,
              iotFlag:0,
              videoAiFlag:0,
              linphoneFlag:0,
              displayFlag:0,
              selfTestFlag:0,
              linkageFlag:0,
              videoParamFlag:0,
            }
          }
        })
      }else if(type == 'update') {
        this.devicenum = record.devicenum;
        // this.modelnum = record.modelnum;
        this.updateModalVisible = true;
      }else if(type=='recover'){
        let that=this;
        that.$confirm('您确定要重启设备吗?',{
          type:'warning',
          centered:true,
        }).then(()=>{
          that.devicenum = record.devicenum;
          let paramsBody = {
            Reboot:'1'
          }
          let params = {
            devicenum: that.devicenum,
            data: [
              {
                modeltype: that.devicemoduletype,
                modelnum: that.modelnum,
                params: {
                  methord: 'PUT',
                  url:'/mqttclient',
                  body: paramsBody,
                }
              }
            ]
          };
          that.showLoading();
          setDeviceparamValueList(params).then(res => {
            that.hideLoading();
            if(res && res.returncode == '0') {
              let param = {
                username:that.username,
                recoverobj:record.devicename,
                recoverdate:moment(new Date()).format("YYYYMMDDHHmmss"),
                devicenum:that.devicenum,
                devicegroupid:'00',
                orderresult:'成功',
                recovertype:'0',
              }
              addRecoverLog(param)
              that.$message.success('操作成功');
            }else {
              that.$message.error(res.errormsg||'操作失败');
              let param = {
                username:that.username,
                recoverobj:record.devicename,
                recoverdate:moment(new Date()).format("YYYYMMDDHHmmss"),
                devicenum:that.devicenum,
                devicegroupid:'0',
                orderresult:'失败',
                recovertype:'0',
              }
              addRecoverLog(param)
            }
        }).catch(()=>{
            that.hideLoading();
            that.$message.error('操作失败');
            let param = {
              username:that.username,
              recoverobj:record.devicename,
              recoverdate:moment(new Date()).format("YYYYMMDDHHmmss"),
              devicenum:that.devicenum,
              devicegroupid:'0',
              orderresult:'失败',
              recovertype:'0',
            }
            addRecoverLog(param)
          })
        });
        // that.$confirm({
        //   title: '您确定要重启设备吗?',
        //   content: '如果您确定需要重启，请单击确认键以完成重启。',
        //   onOk() {
        //     return new Promise((resolve, reject) => {
        //       setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
        //       that.devicenum = record.devicenum;
        //       let paramsBody = {
        //         Reboot:'1'
        //       }
        //       let params = {
        //         devicenum: that.devicenum,
        //         data: [
        //           {
        //             modeltype: that.devicemoduletype,
        //             modelnum: that.modelnum,
        //             params: {
        //               methord: 'PUT',
        //               url:'/iot',
        //               body: paramsBody,
        //             }
        //           }
        //         ]
        //       };
        //       that.showLoading();
        //       setDeviceparamValueList(params).then(res => {
        //         that.hideLoading();
        //         if(res && res.returncode == '0') {
        //           let param = {
        //             username:that.username,
        //             recoverobj:record.devicename,
        //             recoverdate:moment(new Date()).format("YYYYMMDDHHmmss"),
        //             devicenum:that.devicenum,
        //             devicegroupid:'00',
        //             orderresult:'成功',
        //             recovertype:'0',
        //           }
        //           addRecoverLog(param)
        //           that.$message.success('操作成功');
        //         }else {
        //           that.$message.error(res.errormsg||'操作失败');
        //           let param = {
        //             username:that.username,
        //             recoverobj:record.devicename,
        //             recoverdate:moment(new Date()).format("YYYYMMDDHHmmss"),
        //             devicenum:that.devicenum,
        //             devicegroupid:'0',
        //             orderresult:'失败',
        //             recovertype:'0',
        //           }
        //           addRecoverLog(param)
        //         }
        //       }).catch(err => {
        //         that.hideLoading();
        //         that.$message.error('操作失败');
        //         let param = {
        //           username:that.username,
        //           recoverobj:record.devicename,
        //           recoverdate:moment(new Date()).format("YYYYMMDDHHmmss"),
        //           devicenum:that.devicenum,
        //           devicegroupid:'0',
        //           orderresult:'失败',
        //           recovertype:'0',
        //         }
        //         addRecoverLog(param)
        //       })
        //     }).catch(() => console.log('Oops errors!'));
        //   },
        //   onCancel() {
        //   },
        // });
      } else {
        this.showModal(type, deviceinfoid, record)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.resize-table-th {
  position: relative;
  .table-draggable-handle {
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
    position: absolute;
  }
}
</style>