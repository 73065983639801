<!--显示屏列表-->
<template>
  <div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="显示屏名称" prop="monitorname">
              <a-input v-model.trim="queryParams.monitorname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="显示屏编号" prop="monitornum">
              <a-input v-model.trim="queryParams.monitornum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="显示屏类型" prop="monitortype">
              <a-select v-model="queryParams.monitortype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in monitortypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯注册码" prop="liftnum">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">显示屏列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="monitorid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="monitortype" slot-scope="text">
            <span class="text">{{monitortypesMap[text]||''}}</span>
          </span>
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.monitorid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.monitorid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.monitorid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :terminaltypes="monitortypes" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </div>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getCache, getItemFromArrayByKey} from 'U'
import {
  getTerminaltypeListByCondition,
  getDeviceinfoListByCondition,
  deleteDeviceinfo,
  getDevicegroupdevListByCondition, setDeviceparamValueList, addRecoverLog, deleteMonitorinfo
} from 'A/ai'
import addOrEditModal from './addOrEditModal'
import {getDevicegroupListByCondition, getMonitorinfoListByCondition, getMonitortypeListByCondition} from "A/ai";
import moment from "moment";
export default {
  mixins: [pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      showAdvanced: false,
      monitortypes: [],
      queryParams: {
        monitorname: '',
        monitornum: '',
        monitortype: '',
        liftrescue: '',
        liftnum: '',
        adress: '',
      },
      tableColumns: [
        {
          title: '显示屏名称',
          dataIndex: 'monitorname',
          key: 'monitorname',
          ellipsis: true,
        },
        {
          title: '显示屏编号',
          dataIndex: 'monitornum',
          key: 'monitornum',
          ellipsis: true,
        },
        {
          title: '显示屏型号',
          dataIndex: 'monitortype',
          key: 'monitortype',
          ellipsis: true,
          scopedSlots: { customRender: 'monitortype' }
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
        {
          title: '电梯救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '电梯注册码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '电梯地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'monitordecs',
          key: 'monitordecs',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      username:'',
    }
  },
  computed: {
    monitortypesMap() {
      let result = {};
      this.monitortypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    }
  },
  created() {
    this.getMonitortypes();
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.username = logininfo.usernames;
    }
  },
  methods: {
    getMonitortypes() {
      getMonitortypeListByCondition({}).then(res => {
        if(res && res.returncode == '0') {
          this.monitortypes = res.item.map(item => {
            return {
              value: item.monitortypeid,
              text: item.monitortypename,
            }
          });
        }
        this.getTableData();
      }).catch(err => {
        this.getTableData();
      })
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMonitorinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.monitorid);
      }).catch(()=>{
      });
    },
    delete(monitorid) {
      this.showLoading();
      if(monitorid) {
        let params = {
          monitorid
        };
        deleteMonitorinfo(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let monitorid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'monitorid', monitorid);
      if(type == 'delete') {
        this.deleteConfirm(monitorid, record)
      } else {
        this.showModal(type, monitorid, record)
      }
    },
  }
}
</script>