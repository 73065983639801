<template>
  <div>
    <a-modal v-model="modalVisible" title="版本升级" :width="560" :centered="true">
      <template slot="footer">
        <a-button @click="modalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :label-col="{span:7}" :wrapper-col="{span:12}">
        <a-form-model-item label="版本">
          <a-input :value="versionObj?versionObj.version:''" style="width: 65%;" disabled />
          <a-button type="primary" style="width: 30%; margin-left: 5%" @click="selectVersionModalVisible=true">选择</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <select-version-modal select-type="radio" :visible.sync="selectVersionModalVisible" :default-selected="versionObj?[versionObj]:[]" @get-selected-rows="getDevicemodelSelectRows" :devicemoduletype="devicemoduletype" @getMsg="getMsg(arguments)"></select-version-modal>
    <a-modal v-model="messVisible" title="提示信息" :width="760" :centered="true">
      <div ref="modalContainer" class="modal-container">
        <div ref="successmess" v-if="successmess">
          <div style="text-align: center">
            <i class="el-icon-circle-check"  style="font-size: 150px"></i>
          </div>
          <div style="font-size: 40px;text-align: center">升级参数发送成功！</div>
        </div>
        <div ref="defaultmess" v-if="defaultmess">
          <div style="text-align: center">
            <i class="el-icon-circle-close" style="font-size: 150px;"></i>
          </div>
          <div style="font-size: 40px;text-align: center">升级参数发送失败，请重试！</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import SelectVersionModal from 'C/modals/SelectVersionModal.vue'
import {
  calculateVersion,
  getDeviceinfoListByCondition,
  updateVersion
} from 'A/ai'
import {addUpgradeLog} from "A/ai";
import {getCache} from "U/index";
export default {
  components: {
    SelectVersionModal,
  },
  props: {
    visible: {
      default: false
    },
    devicemoduletype: {
      default: ''
    },
    devicenum: {
      default: ''
    },
    modelnum: {
      default: ''
    },
    // modeltype: Array,
  },
  data() {
    return {
      modalVisible: false,
      selectVersionModalVisible: false,
      versionObj: null,
      messVisible:false,
      successmess:'',
      defaultmess:'',
      devicename:'',
      currentDate:'',
      frontversion:'',
      msg:'',
      msg1:'',
      data:'',
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getMsg:function (msg){
      this.msg=msg[0].toString()
      this.msg1=msg[1].toString()
      this.frontversion=this.msg1
    },
    initDetail() {
      this.resetDatas();
      this.getDevicemoduleVersion();
    },
    resetDatas() {
      this.versionObj = null;
    },
    getDevicemodelSelectRows(rows) {
      this.versionObj = rows[0]
    },
    confirm() {
      this.modalVisible = false;
      this.currentDate=moment(new Date).format("YYYYMMDDHHmmss");
      this.insertUpgradeLog();
      if(this.versionObj) {
        let params = {
          devicenum: this.devicenum,
          data: [{
            modeltype: this.msg,
            modelnum: this.modelnum,
            version: this.versionObj.version,
            file: this.versionObj.filename,
            md5: this.versionObj.md5,
          }],
          updatedate:this.currentDate,
        }
        updateVersion(params)
        this.$message.success('操作成功');
        this.$emit('get-operation-result', 'success', '操作成功');
        this.sertSuccess();
      }else {
        this.$message.error('请选择版本');
      }
    },
    getDevicemoduleVersion(){
      let params ={
        devicenum:this.devicenum,
        devicemoduletype: this.msg,
      }
      getDeviceinfoListByCondition(params).then(res=> {
        if (res && res.returncode == '0') {
          this.devicename = res.item[0].devicename
        }
      })
    },
    insertUpgradeLog(){
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      let param={
        devicenum:this.devicenum,
        modeltype: this.msg,
        frontversion:this.frontversion,
        username:this.username,
        version:this.versionObj.version,
        file:this.versionObj.filename,
        upgradedate:this.currentDate,
        upgradeobj:this.devicename,
        result:'',
        md5: this.versionObj.md5,
        devicegroupid:'0',
        upgradetype:'0',
      }
      let params = {
        item:param
      }
      addUpgradeLog(params)
      let data={
        upgradedate: this.currentDate,
        modeltype: this.msg,
        upgradetype: '0',
        precompletetime: '3600000',
      }
      calculateVersion(data)
    },

    sertSuccess(){
      this.messVisible=true;
      this.successmess=true;
      setTimeout(() => {
        this.messVisible=false;
      }, 1500);
    },
    sertdefault() {
      this.messVisible=true;
      this.defaultmess=true;
      setTimeout(() => {
        this.messVisible=false;
      }, 1500);
    },
  }
}
</script>